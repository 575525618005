@keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
  

.hero_text {
    transform: scale(0.94);
    animation: scale 1.3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}


.hero_text span {
    opacity: 0;
    filter: blur(4px);
  }
  
  .hero_text span:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .hero_text span:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .hero_text span:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .hero_text span:nth-child(4) {
    animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .hero_text span:nth-child(5) {
    animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .hero_text span:nth-child(6) {
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .hero_text span:nth-child(7) {
    animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }


.hero_support{
    /* @extend %text-xl;
    color: $dark-50;
    font-weight: 400;
    margin-top: 2.4rem; */
    opacity: 0;
    filter: blur(4px);
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);

}

.hero_buttons{
    /* gap: 1.2rem;
    display: flex;
    margin-top: 4.8rem;
    opacity: 0; */
    filter: blur(4px);
    animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);

}

    /* font-weight: 600;
    color: white; */